<template>
    <div>
        <h1>Sales Rebate Companies</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Results />
        </form>
    </div>
</template>

<script>
import { store } from '@/store/Report.store.js'
import Options from './sales_rebate_companies_opt.vue'
import GroupBy from './utils/GroupBy.vue'
import Results from "./utils/Results";

export default {
    name: "sales_rebate_companies",
    components: {
        Options,
        GroupBy,
        Results,
    },
    data() {
        return {
            state: store.state,
            optionFields: {
                startShipDate: {val: new Date().toLocaleDateString(), qsField: '[tracker][dates][actual_ship_from]'},
                endShipDate: {val: new Date().toLocaleDateString(), qsField: '[tracker][dates][actual_ship_to]'},
                startCreditDate: {val: new Date().toLocaleDateString(), qsField: '[credit][dates][cr.credit_date_from]'},
                endCreditDate: {val: new Date().toLocaleDateString(), qsField: '[credit][dates][cr.credit_date_to]'},
                cdiLocationId: {val: 0, qsField: '[union][numbers][clo.cdi_location_id]'},
            },
            groupByFields: {
                cdi_location: {label: 'CDI Location'},
            },
            dataFields: {}
        }
    },
    methods: {
        runReport: function() {
            store.runReport();
        }
    },
    created() {
        this.$appStore.setTitle(['Sales Rebate Companies']);
        this.$set(store.state.results, this.$options.name, '');
        store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
    },
}
</script>

<style scoped>

</style>